
import Vue from "vue";
import Component from "vue-class-component";
import AppSidebar from "@/containers/AppSidebar.vue";
import DashboardTiles from "@/components/DashboardTiles.vue";
import DashboardTile from "@/components/DashboardTile.vue";
import nav from "./_dashboard_nav";
import { ContentToRender } from '@coreui/vue';

Vue.component('DashboardTiles', DashboardTiles);
Vue.component('DashboardTile', DashboardTile);

@Component({
  components: { AppSidebar }
})
export default class Dashboard extends Vue {
  tiles: ContentToRender = nav;
}
