import { ContentFlat } from '@coreui/vue';

const nav: ContentFlat = [
  {
    _name: 'DashboardTiles',
    _children: [
      {
        _name: 'DashboardTile',
        name: 'Home',
        to: '/dashboard',
        fontIcon: 'home',
      },
      {
        _name: 'DashboardTile',
        name: 'MR',
        to: '/mr',
        fontIcon: 'list',
      },
      /*{
        _name: 'DashboardTile',
        name: 'Schedule',
        to: '/estimates/list',
        fontIcon: 'calendar',
      },*/
      {
        _name: 'DashboardTile',
        name: 'Parts',
        to: '/part',
        fontIcon: 'wrench',
      },
      {
        _name: 'DashboardTile',
        name: 'Locations',
        to: '/location/mr',
        fontIcon: 'map',
      },
      {
        _name: 'DashboardTile',
        name: 'Technicians',
        to: '/technician',
        fontIcon: 'users',
      },
      {
        _name: 'DashboardTile',
        name: 'Payments',
        to: '/payment',
        fontIcon: 'money-check',
      },
      {
        _name: 'DashboardTile',
        name: 'Request',
        to: '/part-request',
        fontIcon: 'shopping-cart',
      }/*,
      {
        _name: 'DashboardTile',
        name: 'App users',
        to: '/tools/my-account',
        fontIcon: 'address-card'
      },*/
    ]
  }
];

export default nav;