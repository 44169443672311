
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DashboardTiles extends Vue {
  
}

// export default {
//   name: 'DashboardTiles',
//   inject: {
//     state: {
//       default: false
//     }
//   },
// }
