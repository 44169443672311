
import Vue from "vue";
import Component from "vue-class-component";
import nav from "./_nav";
import { ContentToRender, ContentFlat, NodeFlat } from "@coreui/vue";

@Component
export default class AppSidebar extends Vue {
  show: "responsive" | boolean = "responsive";

  minimize: boolean = false;

  nav: ContentFlat = nav;

  /*data() {
    return {
      minimize: false,
      nav,
      show: "responsive"
    };
  }*/

  mounted() {
    this.$root.$on("toggle-sidebar", () => {
      const sidebarOpened = this.show === true || this.show === "responsive";
      this.show = sidebarOpened ? false : "responsive";
    });
    this.$root.$on("toggle-sidebar-mobile", () => {
      const sidebarClosed = this.show === "responsive" || this.show === false;
      this.show = sidebarClosed ? true : "responsive";
    });
  }
}
