<template>
  <CCol col="3" class="mb-4 align-self-stretch">
    <slot>
      <div class="btn-dark-sf w-100">
        <CLink :class="linkClasses" v-bind="computedLinkProps" @click.native="click">
          <div class="w-100">
            <CIcon v-if="icon" v-bind="computedIcon" fill="#fff" class="w-50 m-auto" />
          </div>
          <div class="text-center">
            <font-awesome-icon v-if="fontIcon" :icon="['fal', fontIcon]" size="5x" />
            <!-- <i v-if="fontIcon" :class="['dashboard-tile-icon', fontIcon, 'font-5xl', 'clearfix']"></i> -->
          </div>
          <div class="text-center text-uppercase font-weight-bold pt-2">{{name}}</div>
        </CLink>
      </div>
    </slot>
  </CCol>
</template>

<script>
import { props as linkProps } from "@coreui/vue/src/components/link/CLink";
import CIcon from "@coreui/icons-vue/src/CIconRaw.vue";

const props = Object.assign(
  {},
  linkProps,
  {
    exact: {
      type: Boolean,
      default: true
    }
  },
  {
    name: String,
    icon: [String, Object],
    fontIcon: String,
    addLinkClasses: [String, Array, Object]
  }
);

export default {
  name: "DashboardTile",
  components: {
    CIcon
  },
  props,
  computed: {
    computedLinkProps() {
      return Object.keys(linkProps).reduce((props, key) => {
        props[key] = this[key];
        return props;
      }, {});
    },
    linkClasses() {
      return ["p-4", "text-white", this.addLinkClasses];
    },
    computedIcon() {
      if (typeof this.icon === "object") {
        return Object.assign(
          { customClasses: "dashboard-tile-icon" },
          this.icon
        );
      } else {
        return { customClasses: "dashboard-tile-icon", name: this.icon };
      }
    }
  },
  methods: {
    click(e) {
      this.$emit("link-clicked", e);
    }
  }
};
</script>
